export const msg = {
  forgotPassword:
    "To make sure it's really you, we'll need your username and Social Security number or Global PIN.",
  mfaChallenge:
    "To make sure it's really you, we need to send you a verification code.",
  mfaChallengeActivation:
    "To make sure it’s really you, we need to send a verification code to your phone number: ",
  mfaVerify: "Enter the verification code we sent to",
  mfaVerifyCall: "Enter the verification code we sent to",
  forgotPasswordsecurity:
    "To help us protect your account, please answer the security question below. ",
  resetPassword: "Enter your new password below.",
  resetPasswordSuccess:
    "You may now use your new password to securely sign in to your account.",

  validateActivation:
    "To get started, verify your identity by entering your Social Security number or Global PIN below.",
  activateUser: "Set up new login credentials for your account.",
  tokenExpired:
    "To receive a new activation link by email, please select the button below.",
  enrollSecurityQuestions:
    "Security questions help us verify your identity when you make changes to your account.  Select and answer the three questions below.",
  accountActivationSuccess:
    "Welcome to Credit One Bank! You can now continue to sign in to your account.",

  forgotUsername:
    "To make sure it's really you, we'll need your email address and Social Security number or Global PIN.",
  forgotUsername2: "Please enter your Social Security number below.",
  securityQuestion:
    "To help us protect your account, please answer the security question below. ",

  resendActivationMail:
    "To get started, verify your identity by entering your email address and Social Security number or Global PIN below.",
  resendActivation:
    "To get started, verify your identity by entering your phone number and Social Security number or Global PIN below.",
  updateEmail:
    "Enter your email so we can send you an account activation link.",
  resendActivationSuccess:
    "To finish setting up your account, check your email for an activation link.",

  unlockAccount:
    "To unlock your account, we need to make sure it’s you. Enter your username and Social Security number or Global PIN.",
  unlockAccountSuccess:
    "Or, if you need to reset your password, please use the link below.",

  resetMfaInfo:
    "Can’t access your phone or email address to get a verification code?",
  resetAuthentication2:
    "No problem. Let’s reset your two-factor authentication so you can sign in with just your username and password.",
  resetMfaTipMsg:
    "Resetting two-step authentication removes the extra layer of security on your account by no longer requiring a verification code when you sign in.",
  resetMfaTipMsg2:
    "After you sign in, you can update your authentication methods or turn two-factor authentication back on in Settings.",
  resetMfa:
    "First, we need to make sure it’s really you. Please enter your username and Social Security number or Global PIN.",
  securityQuestionMfa:
    "To help us keep your account safe, answer this security question.",
  confirmResetMfa:
    "This reset will remove the extra layer of security by no longer requiring a code when you sign in. You can always turn it back on later in Settings, after you sign in.",
  confirmResetMfa2: "Do you want to reset two-factor authentication?",
  resetMfaSuccess:
    "You’re all set! Two-factor authentication has been reset for your account. You may now continue to sign in with your username and password.",

  requestError: "For assistance, please call Customer Service at ",
  accountNotActive: "Check your email for an activation link.",
  accountUnlockedError:
    "You may continue to sign in with your username and password on the sign in page.",
  accountActive:
    "You may continue to sign in with your username and password on the sign in page.",
  resetMfaError: "For help with your account, please call Customer Service at ",
};

export const extraMsg = {
  needNewCode: "Need a new code?",
  forgotUsernameSuccess:
    "Use the button below to continue to the Sign In page.",
  exampleSSN: "555-55-5555",
  exampleGlobalPin: "6-digit number",
  existingCustomer: "Already have an account?",
  existingCardMember: "Already have an account?",
  enrollPhone: "Get codes by text:",
  enrollEmail: "Get codes by email:",
  mfaCode: "6 digit number",
  voiceMfaCode: "5 digit number",
  updateEmail: "Please provide a valid email address",
};
