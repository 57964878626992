import { Title } from "@creditonebank/c1b-components";
import { useEffect } from "react";
import { msg } from "../../text/form-message.text";
import { formTitle } from "../../text/form-title.text";
import { title } from "../../text/page-title.text";

const RequestError = ({ helpDeskNumber }) => {
  useEffect(() => {
    document.title = title.requestError;
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="bg">
      <div className="form-element-error">
        <Title showHr tag="h2">
          {formTitle.requestError}
        </Title>

        <div className="description">
          <p className="desc2">
            {msg.requestError}
            <span className="help-desk-number">
            {helpDeskNumber}.
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default RequestError;
