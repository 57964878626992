import React, { useEffect, useState } from "react";
import { checklist } from "./password-checklist.text";
import "./password-checklist.styles.css";
import "./password-checklist-text-colors.css";
import { ReactComponent as SuccessIcon } from "../../assets/SmallCheckIcon.svg";
import { ReactComponent as ErrorIcon } from "../../assets/ErrorIcon.svg";
import PropTypes from "prop-types";

const PasswordChecklist = ({ password, error }) => {
  useEffect(() => {
    validatePassword(password);
  }, [password]);

  const [hasLowCap, setHasLowCap] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasLength8, setHasLength8] = useState(false);

  const validatePassword = (password) => {
    if (password.match(/\d+/g)) {
      setHasNumber(true);
    } else {
      setHasNumber(false);
    }

    if (password.match(/[A-Z]+/g) && password.match(/[a-z]+/g)) {
      setHasLowCap(true);
    } else {
      setHasLowCap(false);
    }

    if (password.length > 7) {
      setHasLength8(true);
    } else {
      setHasLength8(false);
    }
  };

  return (
    <div className="checklist-container">
      <p className="headline-password">{checklist.title}</p>
      <div className="checklists-content">
        <div>
          <ul>
            {!error ? (
              <li className={hasLength8 ? "no-bullet" : "normal-list"}>
                {hasLength8 ? (
                  <i className="icon">
                    <SuccessIcon />
                  </i>
                ) : null}
                <div className="checklist"> {checklist.hasLength8}</div>
              </li>
            ) : (
              <li className="no-bullet">
                <i className="icon">
                  <ErrorIcon />
                </i>
                <div className="checklist"> {checklist.hasLength8}</div>
              </li>
            )}
            {!error ? (
              <li className={hasLowCap ? "no-bullet" : "normal-list"}>
                {hasLowCap ? (
                  <i className="icon">
                    <SuccessIcon />
                  </i>
                ) : null}
                <div className="checklist"> {checklist.hasLowCap}</div>
              </li>
            ) : (
              <li className="no-bullet">
                <i className="icon">
                  <ErrorIcon />
                </i>
                <div className="checklist"> {checklist.hasLowCap}</div>
              </li>
            )}
            {!error ? (
              <li className={hasNumber ? "no-bullet" : "normal-list"}>
                {hasNumber ? (
                  <i className="icon">
                    <SuccessIcon />
                  </i>
                ) : null}
                <div className="checklist"> {checklist.hasNumber}</div>
              </li>
            ) : (
              <li className="no-bullet">
                <i className="icon">
                  <ErrorIcon />
                </i>
                <div className="checklist"> {checklist.hasNumber}</div>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

PasswordChecklist.propTypes = {
  password: PropTypes.string,
  error: PropTypes.bool,
};

PasswordChecklist.defaultProps = {
  password: "",
  error: false,
};

export default PasswordChecklist;
