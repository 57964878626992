import React, { useState, useEffect } from "react";
import { api } from "../../utils/api";
import { useLocation, useNavigate } from "react-router-dom";
import { href } from "../../utils/ui-urls";
import { msg } from "../../text/form-message.text";
import { button } from "../../text/button.text";
import { title } from "../../text/page-title.text";
import { linkText } from "../../text/links.text";
import { formTitle } from "../../text/form-title.text";
import { InputBase, Button, Title, Label } from "@creditonebank/c1b-components";
import Alert from "../../components/alert/alert.component";
import { LoaderComponent } from "../../components/loader/loader.component";
import { errorMsg } from "../../text/error-message.text";
import { axiosInstance } from "../../service/https.service";
import { errorResponse } from "../../text/error.responses.text";
import { checkServerError } from "../../functions/check-server-error.function";
import { checkStateBaseAPI } from "../../functions/check-state.function";
import { BackToSignIn } from "../../components/link/back-to-signin.component";
import { HelpLink } from "../../components/link/help-link.component";

const SecurityQuestionForgotPassword = ({ baseAPI, signInUrl, helpLink }) => {
  useEffect(() => {
    document.title = title.forgotPasswordSecurity;
    window.scrollTo(0, 0);
    if (checkStateBaseAPI(state, baseAPI)) {
      window.location.href = "/";
    }
  }, []);

  useEffect(() => {
    getRandomSecurityQuestion();
  }, []);

  const getRandomSecurityQuestion = async () => {
    setShowLoader(true);
    try {
      const response = await axiosInstance.get(
        baseAPI + state.id + api.getRandomSecurityQuestion,
        {
          headers: {
            size: 1,
          },
        }
      );
      setShowLoader(false);
      const securityQuestion = response.data[0];
      setQuestionId(securityQuestion.id);
      setGroup(securityQuestion.group);
      setSecurityQuestion(securityQuestion.text);
    } catch (error) {
      if (checkServerError(error.response.status)) {
        setShowLoader(false);
        setErrorTitle(errorMsg.internalServer.errorTitle);
        setErrorDescription(errorMsg.internalServer.errorDesc);
        setError(true);
        window.scrollTo(0, 0);
      } else {
        navigate(href.requestError, { replace: true });
      }
    }
  };

  const [errorTitle, setErrorTitle] = useState("");
  const [errorDescription, setErrorDescription] = useState("");
  const [error, setError] = useState(false);
  const [questionId, setQuestionId] = useState("");
  const [group, setGroup] = useState("");
  const [securityQuestion, setSecurityQuestion] = useState("");
  const [securityAnswer, setSecurityAnswer] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation();

  function handleSubmit(e) {
    e.preventDefault();
    setErrorTitle("");
    setErrorDescription("");
    setError(false);

    if (securityAnswer === "") {
      setErrorTitle(errorMsg.missingSecurityAnswer.errorTitle);
      setErrorDescription(errorMsg.missingSecurityAnswer.errorDesc);
      setError(true);
      window.scrollTo(0, 0);
    } else {
      setShowLoader(true);
      axiosInstance
        .post(baseAPI + state.id + api.validateSecurityQuestion, [
          {
            id: questionId,
            group: group,
            text: securityQuestion,
            answer: securityAnswer,
          },
        ])
        .then((response) => {
          setShowLoader(false);
          if (response.data.status === "success") {
            setError(false);
            navigate(href.resetPassword, {
              state: {
                id: state.id,
              },
              replace: true,
            });
          }
        })
        .catch((error) => {
          if (checkServerError(error.response.status)) {
            setShowLoader(false);
            setErrorTitle(errorMsg.internalServer.errorTitle);
            setErrorDescription(errorMsg.internalServer.errorDesc);
            setError(true);
            window.scrollTo(0, 0);
          } else {
            setShowLoader(false);
            if (
              error.response.data.error.title === errorResponse.accountSuspended
            ) {
              navigate(href.requestError, { replace: true });
            }
            setErrorTitle(error.response.data.error.title);
            setErrorDescription(error.response.data.error.message);
            getRandomSecurityQuestion();
            setError(true);
            window.scrollTo(0, 0);
          }
        });
    }
  }

  const handleChange = () => {
    setError(false);
  };

  return (
    <div className="bg">
      {showLoader ||
      securityQuestion === null ||
      securityQuestion === "" ||
      securityQuestion === undefined ? (
        <LoaderComponent />
      ) : (
        <div className="form-element-short">
          <form onSubmit={(e) => handleSubmit(e)} className="form">
            <div className="title">
              <Title showHr tag="h2" className="web-title">
                {formTitle.forgotPasswordSecurity}
              </Title>

              <Title showHr tag="h2" className="mobile-title" align="center">
                {formTitle.forgotPasswordSecurity}
              </Title>
            </div>

            <div className="feedback-alert">
              <Alert
                alertType="error"
                title={errorTitle}
                description={errorDescription}
                showAlert={error}
              />
            </div>

            <div className="description">
              <p className="desc2">{msg.forgotPasswordsecurity}</p>
            </div>

            <Label error={error}>{securityQuestion}</Label>
            <InputBase
              id="security-answer"
              name="security-answer-input"
              setValue={setSecurityAnswer}
              type="security-answer"
              value={securityAnswer}
              error={error}
              noErrorsOrHelper
              hideAlertIcon
              autoFocus
              onChange={handleChange}
              data-testid="test-securityAnswer"
            />

            <Button
              type="submit"
              onClick={handleSubmit}
              size="medium"
              className="button-style-2"
            >
              {button.forgotPasswordSecurity}
            </Button>
            <div className="centre-align inside-box">
              <BackToSignIn signInUrl={signInUrl} />
            </div>
          </form>
        </div>
      )}

      <div className="centre-align out-of-box">
        <HelpLink helpLink={helpLink} />
      </div>
    </div>
  );
};

export default SecurityQuestionForgotPassword;
