import { Title } from "@creditonebank/c1b-components";
import { useEffect } from "react";
import { msg } from "../../text/form-message.text";
import { formTitle } from "../../text/form-title.text";
import { title } from "../../text/page-title.text";

const ResetMfaError = ({ helpDeskNumber }) => {
  useEffect(() => {
    document.title = title.resetMfaError;
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="bg">
      <div className="form-element-error">
        <Title showHr tag="h2">
          {formTitle.resetMfaError}
        </Title>

        <div className="description">
          <p className="desc2">
            {msg.resetMfaError}
            {helpDeskNumber}.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ResetMfaError;
