import React, { useState, useEffect } from "react";
import { api } from "../../utils/api";
import { title } from "../../text/page-title.text";
import { extraMsg, msg } from "../../text/form-message.text";
import { button } from "../../text/button.text";
import { useNavigate } from "react-router-dom";
import { href } from "../../utils/ui-urls";
import { formTitle } from "../../text/form-title.text";
import Tabs from "../../components/tabs/tabs.component";
import {
  InputBase,
  InputSocialSecurity,
  InputOTP,
  Button,
  Title,
  Label,
} from "@creditonebank/c1b-components";
import { label } from "../../text/labels.text";
import { withoutGlobalPinMsg } from "../../text/messages-without-globalpin.text";
import Alert from "../../components/alert/alert.component";
import { LoaderComponent } from "../../components/loader/loader.component";
import { errorMsg } from "../../text/error-message.text";
import { errorResponse } from "../../text/error.responses.text";
import { axiosInstance } from "../../service/https.service";
import { checkServerError } from "../../functions/check-server-error.function";
import { HelpLink } from "../../components/link/help-link.component";

const UnlockAccount = ({ baseAPI, globalPinFlag, helpLink }) => {
  useEffect(() => {
    document.title = title.unlockAccount;
    window.scrollTo(0, 0);
  }, []);

  const [username, setUsername] = useState("");
  const [usernameError, setUsernameError] = useState(false);
  const [governmentIdentifier, setGovernmentIdentifier] = useState("");
  const [ssnError, setSsnError] = useState(false);
  const [globalPin, setGlobalPin] = useState("");
  const [errorTitle, setErrorTitle] = useState("");
  const [errorDescription, setErrorDescription] = useState("");
  const [error, setError] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const navigate = useNavigate();

  function handleSubmit(e) {
    e.preventDefault();
    setErrorTitle("");
    setErrorDescription("");
    setError(false);
    setUsernameError(false);
    setSsnError(false);

    if (
      username === "" &&
      governmentIdentifier === "" &&
      globalPinFlag === false
    ) {
      setError(true);
      setErrorTitle(errorMsg.missingUsernameAndGovtId.errorTitle);
      setErrorDescription(errorMsg.missingUsernameAndGovtId.errorDesc);
      window.scrollTo(0, 0);
    } else if (username === "") {
      setUsernameError(true);
      setErrorTitle(errorMsg.missingUsernameValue.errorTitle);
      setErrorDescription(errorMsg.missingUsernameValue.errorDesc);
      window.scrollTo(0, 0);
    } else if (governmentIdentifier === "") {
      setSsnError(true);
      setErrorTitle(errorMsg.missingGovtId.errorTitle);
      setErrorDescription(errorMsg.missingGovtId.errorDesc);
      window.scrollTo(0, 0);
    } else {
      setShowLoader(true);
      axiosInstance
        .post(baseAPI + api.unlockAccount, {
          login: username,
          governmentIdentifier: governmentIdentifier,
          globalPin: globalPin,
        })
        .then(() => {
          setShowLoader(false);
          setError(false);
          navigate(href.unlockAccountSuccess);
        })
        .catch((error) => {
          if (checkServerError(error.response.status)) {
            setShowLoader(false);
            setErrorTitle(errorMsg.internalServer.errorTitle);
            setErrorDescription(errorMsg.internalServer.errorDesc);
            setError(true);
            window.scrollTo(0, 0);
          } else {
            setShowLoader(false);
            if (
              error.response.data.error.title ===
              errorResponse.unlockAcountError
            ) {
              navigate(href.requestError);
            } else if (
              error.response.data.error.title ===
              errorResponse.accountAlreadyUnlockedError
            ) {
              navigate(href.accountUnlockedError);
            } else {
              setErrorTitle(error.response.data.error.title);
              setErrorDescription(error.response.data.error.message);
              setError(true);
              window.scrollTo(0, 0);
            }
          }
        });
    }
  }

  const handleChange = () => {
    setError(false);
    setUsernameError(false);
    setSsnError(false);
  };

  return (
    <div className="bg">
      {showLoader ? (
        <LoaderComponent />
      ) : (
        <div className="form-element">
          <form onSubmit={(e) => handleSubmit(e)} className="form">
            <div className="title">
              <Title showHr tag="h2" className="web-title">
                {formTitle.unlockAccount}
              </Title>
              <Title showHr tag="h2" className="mobile-title" align="center">
                {formTitle.unlockAccount}
              </Title>
            </div>

            <div className="feedback-alert">
              <Alert
                alertType="error"
                title={errorTitle}
                description={errorDescription}
                showAlert={error || usernameError || ssnError}
              />
            </div>

            <div className="description">
              <p className="desc2">
                {globalPinFlag
                  ? msg.unlockAccount
                  : withoutGlobalPinMsg.unlockAccount}
              </p>
            </div>

            <Label error={error || usernameError}>{label.username}</Label>
            <InputBase
              id="username"
              name="username-input"
              setValue={setUsername}
              type="username"
              value={username}
              error={error || usernameError}
              noErrorsOrHelper
              hideAlertIcon
              autoFocus
              onChange={handleChange}
              data-testid="test-username"
            />

            <div className="govtId-input">
              {globalPinFlag ? (
                <div className="tab-gap">
                  <Tabs>
                    <div label={label.governmentIdentifier}>
                      <InputSocialSecurity
                        id="governmentIdentifier"
                        value={governmentIdentifier}
                        name="governmentIdentifier-input"
                        setValue={setGovernmentIdentifier}
                        error={error || ssnError}
                        noErrorsOrHelper
                        hideAlertIcon
                        description={extraMsg.exampleSSN}
                        onChange={handleChange}
                        data-testid="test-governmentIdentifier"
                      />
                    </div>

                    <div label={label.globalPin}>
                      <InputOTP
                        id="globalPin"
                        value={globalPin}
                        name="globalPin-input"
                        setValue={setGlobalPin}
                        error={error}
                        description={extraMsg.exampleGlobalPin}
                        noErrorsOrHelper
                        hideAlertIcon
                        onChange={handleChange}
                      />
                    </div>
                  </Tabs>
                </div>
              ) : (
                <>
                  <Label error={error || ssnError}>
                    {label.governmentIdentifier}
                  </Label>
                  <InputSocialSecurity
                    id="governmentIdentifier"
                    value={governmentIdentifier}
                    name="governmentIdentifier-input"
                    setValue={setGovernmentIdentifier}
                    error={error || ssnError}
                    noErrorsOrHelper
                    hideAlertIcon
                    description={extraMsg.exampleSSN}
                    onChange={handleChange}
                    data-testid="test-governmentIdentifier"
                  />
                </>
              )}
            </div>
            <Button
              type="submit"
              onClick={handleSubmit}
              size="medium"
              className="button-style-fp"
            >
              {button.unlockAccount}
            </Button>
          </form>
        </div>
      )}

      <div className="centre-align out-of-box">
        <HelpLink helpLink={helpLink} />
      </div>
    </div>
  );
};

export default UnlockAccount;
