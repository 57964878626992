import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { title } from "../../text/page-title.text";
import { msg } from "../../text/form-message.text";
import { href } from "../../utils/ui-urls";
import { formTitle } from "../../text/form-title.text";
import { Title, Icon, Button, ProgressIndicator, } from "@creditonebank/c1b-components";
import { LoaderComponent } from "../../components/loader/loader.component";
import "../../style/mfa-challenge.style.css";
import EmailIcon from "../../assets/EmailIcon.svg";
import MobileMFAIcon from "../../assets/MobileMFAIcon.svg";
import VoiceCallIcon from "../../assets/VoiceCallIcon.svg";
import { axiosInstance } from "../../service/https.service";
import { linkText } from "../../text/links.text";
import { HelpLink } from "../../components/link/help-link.component";
import { checkState } from "../../functions/check-state.function";

const MfaChallenge = ({ baseAPI, stepNumber, stepNames, mobileStepNames, helpLink, }) => {
  useEffect(() => {
    document.title = title.mfaChallenge;
    window.scrollTo(0, 0);
    if (checkState(state)) {
      window.location.href = "/";
    }
    getFactors();
  }, []);

  useEffect(() => {
    getData();
  }, []);

  const getFactors = async () => {
    const factorList = state.factors;
    setCountFactors(factorList.length);
    for (let i = 0; i < factorList.length; i++) {
      let type = factorList[i].type;
      let factorId = factorList[i].id;
      if (type === "email") {
        setEnrolledEmail(true);
        setEmailFactorId(factorId);
      } else if (type === "sms") {
        setEnrolledSMS(true);
        setMobileFactorId(factorId);
      } else if (type === "call") {
        setEnrolledVoice(true);
        setVoiceFactorId(factorId);
      }
    }
  };

  const getData = async () => {
    setMaskedNumber(state.phone);
    setMaskedVoiceCallNumber(state.primaryPhone);
    setMaskedEmail(state.email);
  };

  const { state } = useLocation();
  const [showLoader, setShowLoader] = useState(false);
  const [maskedNumber, setMaskedNumber] = useState("");
  const [maskedEmail, setMaskedEmail] = useState("");
  const [maskedVoiceCallNumber, setMaskedVoiceCallNumber] = useState("");
  const [enrolledEmail, setEnrolledEmail] = useState(false);
  const [enrolledSMS, setEnrolledSMS] = useState(false);
  const [enrolledVoice, setEnrolledVoice] = useState(false);
  const [countFactors, setCountFactors] = useState(0);
  const [emailFactorId, setEmailFactorId] = useState("");
  const [mobileFactorId, setMobileFactorId] = useState("");
  const [voiceFactorId, setVoiceFactorId] = useState("");
  const navigate = useNavigate();

  const handleSecurityQuestion = () => {
    if (state.securityQuestionsAvailable)
      navigate(href.forgotPasswordSecurity, {
        state: {
          id: state.id,
        },
        replace: true,
      });
    else navigate(href.requestError);
  };

  function handleClickSMS(e) {
    e.preventDefault();
    setShowLoader(true);
    axiosInstance.post( 
        baseAPI + state.id + "/factor/" + mobileFactorId + "/challenge",
        null
      )
      .then((response) => {
        setShowLoader(false);
        navigate(href.mfaVerify, {
          state: {
            id: response.data.id,
            phone: maskedNumber,
            email: maskedEmail,
            primaryPhone: maskedVoiceCallNumber,
            data: maskedNumber,
            type: "sms",
            factorId: mobileFactorId,
            factors: response.data.factors,
            countFactors: countFactors,
            securityQuestionsAvailable: state.securityQuestionsAvailable,
            navigateHref: state.navigateHref,
          },
          replace: true,
        });
      })
      .catch((error) => {
        navigate(href.requestError, { replace: true });
      });
  }

  function handleClickEmail(e) {
    e.preventDefault();
    setShowLoader(true);
    axiosInstance.post(
        baseAPI + state.id + "/factor/" + emailFactorId + "/challenge",
        null
      )
      .then((response) => {
        setShowLoader(false);
        navigate(href.mfaVerify, {
          state: {
            id: response.data.id,
            email: maskedEmail,
            phone: maskedNumber,
            primaryPhone: maskedVoiceCallNumber,
            data: maskedEmail,
            type: "email",
            factorId: emailFactorId,
            factors: response.data.factors,
            countFactors: countFactors,
            securityQuestionsAvailable: state.securityQuestionsAvailable,
            navigateHref: state.navigateHref,
          },
          replace: true,
        });
      })
      .catch((error) => {
        navigate(href.requestError, { replace: true });
      });
  }

  function handleClickVoice(e) {
    e.preventDefault();
    setShowLoader(true);
    axiosInstance.post(
        baseAPI + state.id + "/factor/" + voiceFactorId + "/challenge",
        null
      )
      .then((response) => {
        setShowLoader(false);
        navigate(href.mfaVerify, {
          state: {
            id: response.data.id,
            phone: maskedNumber,
            email: maskedEmail,
            primaryPhone: maskedVoiceCallNumber,
            data: maskedVoiceCallNumber,
            type: "call",
            factorId: voiceFactorId,
            factors: response.data.factors,
            countFactors: countFactors,
            securityQuestionsAvailable: state.securityQuestionsAvailable,
            navigateHref: state.navigateHref,
          },
          replace: true,
        });
      })
      .catch((error) => {
        navigate(href.requestError, { replace: true });
      });
  }

  const SmsFactor = ({ mobileNumber }) => {
    return (
      <div className="mfa-factor">
        <li className="factor-option" onClick={handleClickSMS}>
          <a className="factor-link" href="">
            <Icon src={MobileMFAIcon}></Icon>
            <div className="factor-text">
              Text code to: <p>{mobileNumber}</p>
            </div>
          </a>
        </li>
      </div>
    );
  };

  const EmailFactor = ({ email }) => {
    return (
      <div className="mfa-factor">
        <li className="factor-option" onClick={handleClickEmail}>
          <a className="factor-link" href="">
            <Icon src={EmailIcon}></Icon>
            <div className="factor-text">
              Email code to: <p>{email}</p>
            </div>
          </a>
        </li>
      </div>
    );
  };

  const VoiceFactor = ({ voiceCallNumber }) => {
    return (
      <div className="mfa-factor">
        <li className="factor-option" onClick={handleClickVoice}>
          <a className="factor-link" href="">
            <Icon src={VoiceCallIcon} className="voice-call-icon-style"></Icon>
            <div className="factor-text">
              Voice call to: <p>{voiceCallNumber}</p>
            </div>
          </a>
        </li>
      </div>
    );
  };

  return (
    <div className="bg">
      {state != null ? (state.navigateHref === href.updateEmail ? (
          <div className="progress-stepper-style validate-progress">
            <ProgressIndicator
              className="web-progress-stepper"
              currentIndex={stepNumber}
              stepNames={stepNames}
            />
            <ProgressIndicator
              className="mobile-progress-stepper"
              currentIndex={stepNumber}
              stepNames={mobileStepNames}
            />
          </div>
        ) : null
      ) : null}

      {showLoader ? (
        <LoaderComponent />
      ) : (
        <div className="form-element mfa-container">
          <div className="title">
            <Title showHr tag="h2" className="web-title">
              {formTitle.mfaChallenge}
            </Title>

            <Title showHr tag="h2" className="mobile-title" align="center">
              {formTitle.mfaChallenge}
            </Title>
          </div>

          <div className="description">
            <p className="desc2">
              {state != null
                ? state.navigateHref === href.updateEmail
                  ? msg.mfaChallengeActivation
                  : msg.mfaChallenge
                : null}
            </p>
          </div>
          {enrolledSMS ? <SmsFactor mobileNumber={maskedNumber} /> : null}
          {enrolledEmail ? <EmailFactor email={maskedEmail} /> : null}
          {enrolledVoice ? (<VoiceFactor voiceCallNumber={maskedVoiceCallNumber} />
          ) : null}
        </div>
      )}
      <div className="centre-align out-of-box">
        {state != null ? (state.navigateHref === href.updateEmail ? (
            <HelpLink helpLink={helpLink} />
          ) : (
            <div>
              <p className="p-center-align">Not able to receive codes?</p>

              <Button
                variant="link"
                size="small"
                style={{ padding: "0", margin: "0" }}
                onClick={handleSecurityQuestion}
              >
                {linkText.answerSecurityQuestion}
              </Button>
            </div>
          )
        ) : null}
      </div>
    </div>
  );
};

export default MfaChallenge;
