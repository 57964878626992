import React, { useState, useEffect } from "react";
import { api } from "../../utils/api";
import { title } from "../../text/page-title.text";
import { extraMsg, msg } from "../../text/form-message.text";
import { label } from "../../text/labels.text";
import { button } from "../../text/button.text";
import { useNavigate } from "react-router-dom";
import { href } from "../../utils/ui-urls";
import { linkText } from "../../text/links.text";
import { headline } from "../../text/form-headline.text.js";
import Tabs from "../../components/tabs/tabs.component";
import { formTitle } from "../../text/form-title.text";
import {
  InputEmail,
  InputSocialSecurity,
  InputOTP,
  Button,
  Title,
  Label,
} from "@creditonebank/c1b-components";
import { withoutGlobalPinMsg } from "../../text/messages-without-globalpin.text";
import Alert from "../../components/alert/alert.component";
import { LoaderComponent } from "../../components/loader/loader.component";
import { errorMsg } from "../../text/error-message.text";
import { errorResponse } from "../../text/error.responses.text";
import { axiosInstance } from "../../service/https.service";
import { checkServerError } from "../../functions/check-server-error.function";
import { BackToSignIn } from "../../components/link/back-to-signin.component";
import { HelpLink } from "../../components/link/help-link.component";

const ForgotUsername = ({ baseAPI, signInUrl, helpLink, globalPinFlag }) => {
  useEffect(() => {
    document.title = title.forgotUsername;
    window.scrollTo(0, 0);
  }, []);

  const [governmentIdentifier, setGovernmentIdentifier] = useState("");
  const [ssnError, setSsnError] = useState(false);
  const [globalPin, setGlobalPin] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [errorTitle, setErrorTitle] = useState("");
  const [errorDescription, setErrorDescription] = useState("");
  const [error, setError] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const navigate = useNavigate();

  function handleSubmit(e) {
    e.preventDefault();
    setErrorTitle("");
    setErrorDescription("");
    setError(false);
    setEmailError(false);
    setSsnError(false);

    if (
      email === "" &&
      governmentIdentifier === "" &&
      globalPinFlag === false
    ) {
      setErrorTitle(errorMsg.missingEmailAndGovtId.errorTitle);
      setErrorDescription(errorMsg.missingEmailAndGovtId.errorDesc);
      setError(true);
      window.scrollTo(0, 0);
    } else if (email === "") {
      setErrorTitle(errorMsg.missingEmailValue.errorTitle);
      setErrorDescription(errorMsg.missingEmailValue.errorDesc);
      setEmailError(true);
      window.scrollTo(0, 0);
    } else if (governmentIdentifier === "") {
      setErrorTitle(errorMsg.missingGovtId.errorTitle);
      setErrorDescription(errorMsg.missingGovtId.errorDesc);
      setSsnError(true);
      window.scrollTo(0, 0);
    } else {
      setShowLoader(true);
      axiosInstance
        .post(
          baseAPI + api.forgotUsername,
          {
            emailId: email,
            governmentIdentifier: governmentIdentifier,
            globalPin: globalPin,
          },
          {
            headers: {
              accessEmail: true,
            },
          }
        )
        .then((response) => {
          setShowLoader(false);
          setError(false);
          navigate(href.forgotUsernameSuccess, {
            state: {
              login: response.data.login,
            },
          });
        })
        .catch((error) => {
          if (checkServerError(error.response.status)) {
            setShowLoader(false);
            setErrorTitle(errorMsg.internalServer.errorTitle);
            setErrorDescription(errorMsg.internalServer.errorDesc);
            setError(true);
            window.scrollTo(0, 0);
          } else {
            if (
              error.response.data.error.title === errorResponse.accountNotActive
            ) {
              navigate(href.accountNotActive);
            } else {
              setShowLoader(false);
              setErrorTitle(error.response.data.error.title);
              setErrorDescription(errorMsg.errorForgotUsernameDescFunc());
              setError(true);
              window.scrollTo(0, 0);
            }
          }
        });
    }
  }

  const handleChange = () => {
    setError(false);
    setEmailError(false);
    setSsnError(false);
  };

  const handleForgotUsername2 = () => {
    navigate(href.forgotUsername2);
  };

  return (
    <div className="bg">
      {showLoader ? (
        <LoaderComponent />
      ) : (
        <div className="form-element">
          <form onSubmit={(e) => handleSubmit(e)} className="form">
            <div className="title">
              <Title showHr tag="h2" className="web-title">
                {formTitle.forgotUsername}
              </Title>

              <Title showHr tag="h2" className="mobile-title" align="center">
                {formTitle.forgotUsername}
              </Title>
            </div>

            <div className="feedback-alert">
              <Alert
                alertType="error"
                title={errorTitle}
                description={errorDescription}
                showAlert={error || emailError || ssnError}
              />
            </div>

            <div className="description">
              <p className="headline-style">{headline.forgotUsername}</p>
              <p className="desc2">
                {globalPinFlag
                  ? msg.forgotUsername
                  : withoutGlobalPinMsg.forgotUsername}
              </p>
            </div>
            <div className="box-with-link">
              <Label error={error || emailError}>{label.email}</Label>
              <InputEmail
                id="email"
                value={email}
                name="email-input"
                setValue={setEmail}
                error={error || emailError}
                noErrorsOrHelper
                hideAlertIcon
                autoFocus
                onChange={handleChange}
                data-testid ="test-email"
              />
              <Button
                variant="link"
                size="small"
                style={{ padding: "0", margin: "0" }}
                onClick={handleForgotUsername2}
                data-testid="test-LinkForgotusername2"
              >
                {linkText.noEmailAccess}
              </Button>
            </div>
            <div className="govtId-input">
              {globalPinFlag ? (
                <Tabs>
                  <div label={label.governmentIdentifier}>
                    <InputSocialSecurity
                      id="governmentIdentifier"
                      value={governmentIdentifier}
                      name="governmentIdentifier-input"
                      setValue={setGovernmentIdentifier}
                      error={error || ssnError}
                      noErrorsOrHelper
                      hideAlertIcon
                      description={extraMsg.exampleSSN}
                      onChange={handleChange}
                      data-testid="test-governmentIdentifier"
                    />
                  </div>

                  <div label={label.globalPin}>
                    <InputOTP
                      id="globalPin"
                      value={globalPin}
                      name="globalPin-input"
                      setValue={setGlobalPin}
                      error={error}
                      description={extraMsg.exampleGlobalPin}
                      noErrorsOrHelper
                      hideAlertIcon
                      onChange={handleChange}
                    />
                  </div>
                </Tabs>
              ) : (
                <>
                  <Label error={error || ssnError}>
                    {label.governmentIdentifier}
                  </Label>
                  <InputSocialSecurity
                    id="governmentIdentifier"
                    value={governmentIdentifier}
                    name="governmentIdentifier-input"
                    setValue={setGovernmentIdentifier}
                    error={error || ssnError}
                    noErrorsOrHelper
                    hideAlertIcon
                    description={extraMsg.exampleSSN}
                    onChange={handleChange}
                    data-testid="test-governmentIdentifier"
                  />
                </>
              )}
            </div>

            <Button
              type="submit"
              onClick={handleSubmit}
              size="medium"
              className="button-style-fp"
            >
              {button.forgotUsername}
            </Button>

            <div className="centre-align inside-box">
              <BackToSignIn signInUrl={signInUrl} />
            </div>
          </form>
        </div>
      )}

      <div className="centre-align out-of-box">
        <HelpLink helpLink={helpLink} />
      </div>
    </div>
  );
};

export default ForgotUsername;
