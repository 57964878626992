import { msg } from "../../text/form-message.text";
import { title } from "../../text/page-title.text";
import { useEffect } from "react";
import {
  Button,
  Title,
  ProgressIndicator,
} from "@creditonebank/c1b-components";
import { formTitle } from "../../text/form-title.text";
import { button } from "../../text/button.text";
import icon from "../../assets/SuccessIcon.svg";

const AcountActivationSuccess = ({ signInUrl }) => {
  useEffect(() => {
    document.title = title.accountActivationSuccess;
    window.scrollTo(0, 0);
  }, []);

  const handleSignIn = () => {
    window.location.href = signInUrl;
  };

  return (
    <div className="bg">
      <div className="progress-stepper-style all-completed">
        <ProgressIndicator currentIndex={4} total={4} />
      </div>
      <div className="form-element-activation-success">
        <div className="set-up-icon-activation-success">
          <img src={icon} alt="success-icon" />
        </div>
        <div className="title">
          <Title showHr tag="h2" align="center">
            {formTitle.accountActivationSuccess}
          </Title>
        </div>
        <div className="description">
          <p className="desc2">{msg.accountActivationSuccess}</p>
        </div>

        <Button
          onClick={handleSignIn}
          size="medium"
          className="button-style-success"
        >
          {button.activationSignIn}
        </Button>
      </div>
    </div>
  );
};

export default AcountActivationSuccess;
