import React, { useState, useEffect } from "react";
import { api } from "../../utils/api";
import { useLocation, useNavigate } from "react-router-dom";
import { href } from "../../utils/ui-urls";
import { msg } from "../../text/form-message.text";
import { button } from "../../text/button.text";
import { title } from "../../text/page-title.text";
import { formTitle } from "../../text/form-title.text";
import {
  Button,
  Title,
  Label,
  InputEmail,
  ProgressIndicator,
} from "@creditonebank/c1b-components";
import Alert from "../../components/alert/alert.component";
import { LoaderComponent } from "../../components/loader/loader.component";
import { errorMsg } from "../../text/error-message.text";
import { axiosInstance } from "../../service/https.service";
import { label } from "../../text/labels.text";
import { checkServerError } from "../../functions/check-server-error.function";
import { checkState } from "../../functions/check-state.function";
import { HelpLink } from "../../components/link/help-link.component";

const UpdateEmail = ({
  baseAPI,
  stepNumber,
  stepNames,
  mobileStepNames,
  helpLink,
}) => {
  useEffect(() => {
    document.title = title.updateEmail;
    window.scrollTo(0, 0);
    if (checkState(state)) {
      window.location.href = "/";
    }
  }, []);

  const [errorTitle, setErrorTitle] = useState("");
  const [errorDescription, setErrorDescription] = useState("");
  const [error, setError] = useState(false);
  const [email, setEmail] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation();

  function handleSubmit(e) {
    e.preventDefault();
    setErrorTitle("");
    setErrorDescription("");
    setError(false);

    if (email === "") {
      setErrorTitle(errorMsg.missingEmailValue.errorTitle);
      setErrorDescription(errorMsg.missingEmailValue.errorDesc);
      setError(true);
      window.scrollTo(0, 0);
    } else {
      setShowLoader(true);
      axiosInstance
        .post(baseAPI + state.id + api.updateEmail, {
          email: email,
        })
        .then((response) => {
          setShowLoader(false);
          navigate(href.resendActivationSuccess, {
            replace: true,
          });
        })
        .catch((error) => {
          if (checkServerError(error.response.status)) {
            setShowLoader(false);
            setErrorTitle(errorMsg.internalServer.errorTitle);
            setErrorDescription(errorMsg.internalServer.errorDesc);
            setError(true);
            window.scrollTo(0, 0);
          } else {
            setErrorTitle(error.response.data.error.title);
            setErrorDescription(error.response.data.error.message);
            setShowLoader(false);
            setError(true);
            window.scrollTo(0, 0);
          }
        });
    }
  }

  const handleChange = () => {
    setError(false);
  };

  return (
    <div className="bg">
      <div className="progress-stepper-style validate-progress">
        <ProgressIndicator
          className="web-progress-stepper"
          currentIndex={stepNumber}
          stepNames={stepNames}
        />
        <ProgressIndicator
          className="mobile-progress-stepper"
          currentIndex={stepNumber}
          stepNames={mobileStepNames}
        />
      </div>
      {showLoader ? (
        <LoaderComponent />
      ) : (
        <div className="form-element-activate-user">
          <form onSubmit={(e) => handleSubmit(e)} className="form">
            <div className="title">
              <Title showHr tag="h2" className="web-title">
                {formTitle.updateEmail}
              </Title>

              <Title showHr tag="h2" className="mobile-title" align="center">
                {formTitle.updateEmail}
              </Title>
            </div>

            <div className="feedback-alert">
              <Alert
                alertType="error"
                title={errorTitle}
                description={errorDescription}
                showAlert={error}
              />
            </div>

            <div className="description">
              <p className="desc2">{msg.updateEmail}</p>
            </div>

            <Label error={error}>{label.email}</Label>
            <InputEmail
              id="email"
              value={email}
              name="email-input"
              setValue={setEmail}
              error={error}
              noErrorsOrHelper
              hideAlertIcon
              autoFocus
              onChange={handleChange}
              data-testid="test-email"
            />

            <Button
              type="submit"
              onClick={handleSubmit}
              size="medium"
              className="button-style-2"
            >
              {button.updateEmail}
            </Button>
          </form>
        </div>
      )}

      <div className="centre-align out-of-box">
        <HelpLink helpLink={helpLink} />
      </div>
    </div>
  );
};

export default UpdateEmail;
