import React, { useEffect } from "react";
import { successMsg } from "../../text/success.text";
import { msg } from "../../text/form-message.text";
import { title } from "../../text/page-title.text";
import { formTitle } from "../../text/form-title.text";
import { Title } from "@creditonebank/c1b-components";
import Alert from "../../components/alert/alert.component";

const ResendActivationSuccess = () => {
  useEffect(() => {
    document.title = title.resendActivationSuccess;
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="bg">
      <div className="form-element-resend-success">
        <div className="title">
          <Title showHr tag="h2" className="web-title">
            {formTitle.resendActivationSuccess}
          </Title>
          <Title showHr tag="h2" className="mobile-title" align="center">
            {formTitle.resendActivationSuccess}
          </Title>
        </div>

        <div className="feedback-success">
          <Alert
            alertType="success"
            title={successMsg.resendActivationSuccess}
            showAlert={true}
          />
        </div>

        <p className="inform">{msg.resendActivationSuccess}</p>
      </div>
    </div>
  );
};

export default ResendActivationSuccess;
