import React, { useState, useEffect } from "react";
import "./style/Styles.css";
import "./style/c1b-components.style.css";
import "./style/mobile-styles.css";
import "./style/enroll-mfa.style.css";
import "./style/button.style.css";
import { Header } from "@creditonebank/c1b-components";
import { Route, Routes, useLocation, Navigate, useNavigate } from "react-router-dom";
import Home from "./Home";

import ValidateActivation from "./pages/account-set-up/validate-activation.page";
import ActivateUser from "./pages/account-set-up/activate-user.page";
import EnrollSecurityQuestions from "./pages/account-set-up/enroll-security-questions.page";
import TokenExpired from "./pages/account-set-up/token-expired.page";
import AcountActivationSuccess from "./pages/account-set-up/success.page";

import ForgotPassword from "./pages/forgot-password/forgot-password.page";
import MfaChallenge from "./pages/mfa-challenge/mfa-challenge.page";
import MfaVerify from "./pages/mfa-challenge/mfa-verify.page";
import SecurityQuestionForgotPassword from "./pages/forgot-password/security-question.forgot-password";
import ResetPassword from "./pages/forgot-password/reset-pasword.page";
import ResetPasswordSuccess from "./pages/forgot-password/success.page";

import ForgotUsername from "./pages/forgot-username/forgot-username.page";
import ForgotUsername2 from "./pages/forgot-username/forgot-username-2.page";
import SecurityQuestion from "./pages/forgot-username/security-question.forgot-username";
import ForgotUsernameSuccess from "./pages/forgot-username/success.page";

import UnlockAccount from "./pages/unlock-account/unlock-account.page";
import UnlockAccountSuccess from "./pages/unlock-account/success.page";

import ResendActivationMail from "./pages/resend-activation-mail/resend-activation-mail.page";
import ResendActivation from "./pages/resend-activation-mail/resend-activation.page";
import UpdateEmail from "./pages/resend-activation-mail/update-email.page";
import ResendActivationSuccess from "./pages/resend-activation-mail/success.page";

import SessionExpired from "./pages/error/session-expired.error.page";
import AccountNotActive from "./pages/error/account-error.page";
import RequestError from "./pages/error/request-error.error.page";
import AccountUnlockedError from "./pages/error/account-unlocked.error.page";
import AccountActive from "./pages/error/account-active.error.page";
import ResetMfaError from "./pages/error/reset-mfa.error.page";
import AccountLocked from "./pages/error/account-locked.error.page";

import { FooterComponent } from "./components/footer/footer.component";
import { href } from "./utils/ui-urls";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { LoaderComponent } from "./components/loader/loader.component";
import { Security } from "@okta/okta-react";
import Dashboard from "./components/dashboard/dashboard";
import { SecureRoute } from "./components/dashboard/secureRoute";
import Login from "./components/dashboard/login";
import SessionTimedOut from "./components/dashboard/sessionTimedOut";

const App = () => {
  const initialEnvState = {
    signInUrl: "",
    accessCreditOneUrl: "",
    baseAPI: "",
    globalPinFlag: false,
    helpLink: "",
    helpDeskNumber: "",
    mobileSignInUrl: "",
    creditCardAppUrl: "",
    depositAppUrl: "",
    creditCardAppRefUrl: "",
    depositAppRefUrl: "",
    sessionExpiredRedirectUri: "",
    sessionIdleTime: "",
    sessionTimer: "",
  };
  const [oktaAuth, setOktaAuth] = useState(null);
  const navigate = useNavigate();
  const restoreOriginalUri = (_oktaAuth, originalUri) => {
    navigate(toRelativeUrl(originalUri || href.dashboard, window.location.origin));
  };

  const getEnvValues = async () => {
    return fetch("/env")
      .then((response) => response.json())
      .then((data) => {
        const newEnv = {
          signInUrl: data.REACT_APP_SIGN_IN_URL,
          accessCreditOneUrl: data.REACT_APP_ACCESS_CREDITONE_URL,
          baseAPI: data.REACT_APP_API_ENDPOINT,
          globalPinFlag:
            data.REACT_APP_FLAG_GLOBALPIN === "true" ? true : false,
          helpLink: data.REACT_APP_HELP_LINK,
          helpDeskNumber: data.REACT_APP_HELPDESK_NUMBER,
          mobileSignInUrl: data.REACT_APP_MOBILE_SIGN_IN_URL,
          creditCardAppUrl: data.REACT_APP_CREDIT_CARD_APP_URL,
          depositAppUrl: data.REACT_APP_DEPOSIT_APP_URL,
          creditCardAppRefUrl: data.REACT_APP_CREDIT_CARD_APP_REF_URL,
          depositAppRefUrl: data.REACT_APP_DEPOSIT_APP_REF_URL,
          sessionExpiredRedirectUri: data.REACT_APP_SESSION_EXPIRED_REDIRECT_URI,
          sessionIdleTime: data.REACT_APP_SESSION_IDLE_TIME,
          sessionTimer: data.REACT_APP_SESSION_TIMER,
        };
        setEnvState(newEnv);

        //Adobe analytics script
        const script = document.createElement("script");
        script.src = data.REACT_APP_ADOBE_ANALYTICS_SRC;
        script.async = true;
        document.head.appendChild(script);

        //Setting okta configs
        const OKTA_TESTING_DISABLEHTTPSCHECK =
          process.env.OKTA_TESTING_DISABLEHTTPSCHECK || false;
        const oktaConfig = {
          clientId: data.REACT_APP_OKTA_CLIENT_ID,
          issuer: data.REACT_APP_OKTA_ISSUER,
          redirectUri: data.REACT_APP_OKTA_REDIRECT_URI,
          scopes: ["openid", "profile", "email", "customer"],
          pkce: true,
          disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
        };
        const newOktaAuth = new OktaAuth(oktaConfig);
        setOktaAuth(newOktaAuth);
      });
  };

  //Passing env state values as props to components
  const [envState, setEnvState] = useState(initialEnvState);

  useEffect(() => {
    getEnvValues();
  }, []);

  const stepNames = ["Verify Your Identity", "Create Your Account", "Security"];
  const mobileStepNames = ["Verify", "Create Account", "Security"];
  const stepNamesActivation = ["Verify Your Identity", "Activation Link", "Security"];
  const mobileStepNamesActivation = ["Verify", "Activation Link", "Security"];

  const ProtectedRoute = () => {
    const location = useLocation();
    let searchParams = new URLSearchParams(location.search);
    const individualUniqueIdentifier = searchParams.get("id");
    const token = searchParams.get("token");
    searchParams.delete(individualUniqueIdentifier);
    searchParams.delete(token);
    return (
      <Navigate replace to={href.validateActivation}
        state={{
          individualUniqueIdentifier: individualUniqueIdentifier,
          token: token,
        }}
      />
    );
  };

  const Redirect404 = () => {
    window.location.href = href.error404;
  };

  //Remove Header, Footer, Need Help and Back to Sign for Mobile App
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paramValue = queryParams.get("isMobile");
  if (paramValue === "true") {
    sessionStorage.setItem("isMobile", paramValue);
  }
  const isMobile = sessionStorage.getItem("isMobile");

  return (
    <>
      {isMobile === "true" ? null : (<Header theme="dark" centeredLogo logoURL={envState.signInUrl} className="header-style" />)}
      {oktaAuth ? (
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
          <Routes>
            {/* Homepage & Dashboard */}
            <Route exact path="/" element={<Home />} />
            <Route path={href.dashboard} element={<SecureRoute />}>
              <Route path={href.dashboard} exact={true} element={<Dashboard creditCardAppUrl={envState.creditCardAppUrl} depositAppUrl={envState.depositAppUrl} creditCardAppRefUrl={envState.creditCardAppRefUrl} depositAppRefUrl={envState.depositAppRefUrl} sessionExpiredRedirectUri={envState.sessionExpiredRedirectUri} sessionIdleTime={envState.sessionIdleTime} sessionTimer={envState.sessionTimer} />} />
            </Route>
            <Route path="/login/callback" element={<Login />} />
            <Route path="/sessionTimedOut" element={<SessionTimedOut />} />

            {/* ForgotPassword */}
            <Route path={href.forgotPassword} element={<ForgotPassword baseAPI={envState.baseAPI} signInUrl={isMobile === "true" ? null : envState.signInUrl} helpLink={isMobile === "true" ? null : envState.helpLink} globalPinFlag={envState.globalPinFlag} />} />
            <Route path={href.mfaChallenge} element={<MfaChallenge baseAPI={envState.baseAPI} stepNumber={1} stepNames={stepNamesActivation} mobileStepNames={mobileStepNamesActivation} helpLink={isMobile === "true" ? null : envState.helpLink} />} />
            <Route path={href.mfaVerify} element={<MfaVerify baseAPI={envState.baseAPI} stepNumber={1} stepNames={stepNamesActivation} mobileStepNames={mobileStepNamesActivation} helpLink={isMobile === "true" ? null : envState.helpLink} />} />
            <Route path={href.forgotPasswordSecurity} element={<SecurityQuestionForgotPassword baseAPI={envState.baseAPI} signInUrl={isMobile === "true" ? null : envState.signInUrl} helpLink={isMobile === "true" ? null : envState.helpLink} />} />
            <Route path={href.resetPassword} element={<ResetPassword baseAPI={envState.baseAPI} helpLink={isMobile === "true" ? null : envState.helpLink} />} />
            <Route path={href.resetPasswordSuccess} element={<ResetPasswordSuccess signInUrl={envState.signInUrl} />} />

            {/* ForgotUsername */}
            <Route path={href.forgotUsername} element={<ForgotUsername baseAPI={envState.baseAPI} signInUrl={isMobile === "true" ? null : envState.signInUrl} helpLink={isMobile === "true" ? null : envState.helpLink} globalPinFlag={envState.globalPinFlag} />} />
            <Route path={href.forgotUsername2} element={<ForgotUsername2 baseAPI={envState.baseAPI} signInUrl={isMobile === "true" ? null : envState.signInUrl} helpLink={isMobile === "true" ? null : envState.helpLink} />} />
            <Route path={href.securityQuestion} element={<SecurityQuestion baseAPI={envState.baseAPI} signInUrl={isMobile === "true" ? null : envState.signInUrl} helpLink={isMobile === "true" ? null : envState.helpLink} />} />
            <Route path={href.forgotUsernameSuccess} element={<ForgotUsernameSuccess signInUrl={envState.signInUrl} />} />

            {/* Online Account Set Up */}
            <Route path={href.userValidate} element={<ProtectedRoute />} />{" "}
            <Route path={href.validateActivation} element={<ValidateActivation baseAPI={envState.baseAPI} signInUrl={envState.mobileSignInUrl} globalPinFlag={envState.globalPinFlag} stepNumber={1} stepNames={stepNames} mobileStepNames={mobileStepNames} />} />
            <Route path={href.activateUser} element={<ActivateUser baseAPI={envState.baseAPI} stepNumber={2} stepNames={stepNames} mobileStepNames={mobileStepNames} helpLink={isMobile === "true" ? null : envState.helpLink} />} />
            <Route path={href.tokenExpired} element={<TokenExpired baseAPI={envState.baseAPI} helpLink={isMobile === "true" ? null : envState.helpLink} />} />
            <Route path={href.enrollSecurityQuestions} element={<EnrollSecurityQuestions baseAPI={envState.baseAPI} stepNumber={3} stepNames={stepNames} mobileStepNames={mobileStepNames} helpLink={isMobile === "true" ? null : envState.helpLink} />} />
            <Route path={href.accountActivationSuccess} element={<AcountActivationSuccess signInUrl={envState.mobileSignInUrl} />} />

            {/* UnlockAccount */}
            <Route path={href.unlockAccount} element={<UnlockAccount baseAPI={envState.baseAPI} globalPinFlag={envState.globalPinFlag} helpLink={isMobile === "true" ? null : envState.helpLink} />} />
            <Route path={href.unlockAccountSuccess} element={<UnlockAccountSuccess signInUrl={envState.signInUrl} helpLink={isMobile === "true" ? null : envState.helpLink} />} />

            {/* Online Account Set Up - From Link */}
            <Route path={href.resendActivationMail} element={<ResendActivationMail baseAPI={envState.baseAPI} signInUrl={envState.signInUrl} globalPinFlag={envState.globalPinFlag} stepNumber={1} stepNames={stepNames} mobileStepNames={mobileStepNames} />} />
            <Route path={href.resendActivation} element={<ResendActivation baseAPI={envState.baseAPI} signInUrl={envState.signInUrl} globalPinFlag={envState.globalPinFlag} stepNumber={1} stepNames={stepNames} mobileStepNames={mobileStepNames} />} />
            <Route path={href.updateEmail} element={<UpdateEmail baseAPI={envState.baseAPI} stepNumber={2} stepNames={stepNamesActivation} mobileStepNames={mobileStepNamesActivation} helpLink={isMobile === "true" ? null : envState.helpLink} />} />
            <Route path={href.resendActivationSuccess} element={<ResendActivationSuccess />} />

            {/* Error Pages */}
            <Route path={href.sessionExpired} element={<SessionExpired />} />
            <Route path={href.accountLocked} element={<AccountLocked />} />
            <Route path={href.accountNotActive} element={<AccountNotActive />} />
            <Route path={href.requestError} element={<RequestError helpDeskNumber={envState.helpDeskNumber} />} />
            <Route path={href.accountUnlockedError} element={<AccountUnlockedError signInUrl={envState.signInUrl} />} />
            <Route path={href.accountActive} element={<AccountActive signInUrl={envState.signInUrl} />} />
            <Route path={href.resetMfaError} element={<ResetMfaError helpDeskNumber={envState.helpDeskNumber} />} />

            {/* 404 Page Not Found */}
            <Route path={"*"} element={<Redirect404 />} />
          </Routes>
        </Security>
      ) : (<LoaderComponent />)}
      {isMobile === "true" ? null : <FooterComponent />}
    </>
  );
};
export default App;
