import React, { useEffect } from "react";
import { msg } from "../../text/form-message.text";
import { title } from "../../text/page-title.text";
import { formTitle } from "../../text/form-title.text";
import { headline } from "../../text/form-headline.text.js";
import { button } from "../../text/button.text";
import { Button, Title } from "@creditonebank/c1b-components";

const ResetPasswordSuccess = ({ signInUrl }) => {
  useEffect(() => {
    document.title = title.resetPasswordSuccess;
    window.scrollTo(0, 0);
  }, []);

  const handleSignIn = () => {
    window.location.href = signInUrl;
  };

  return (
    <div className="bg">
      <div className="form-element-success">
        <Title showHr tag="h2" className="web-title">
          {formTitle.resetPasswordSuccess}
        </Title>

        <Title showHr tag="h2" className="mobile-title" align="center">
          {formTitle.resetPasswordSuccess}
        </Title>
        <div className="description">
          <p className="headline-style desc2">
            {headline.resetPasswordSuccess}
          </p>
          <p className="desc2">{msg.resetPasswordSuccess}</p>
        </div>
        <Button
          onClick={handleSignIn}
          size="medium"
          className="button-style"
          style={{ width: "100%", marginTop: "16px", padding: "12px 32px" }}
        >
          {button.signIn}
        </Button>
      </div>
    </div>
  );
};

export default ResetPasswordSuccess;
